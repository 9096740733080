@import './index.module.scss';

html, body {
  height: 100%;
  line-height: 1.45;
}

a {
  text-decoration: none;
  outline: 0;
}

body {
  margin: 0;
  background: white;
}

body, button {
  // Optmizes font rendering for legibility.
  // May break with some browsers, comment out as necessary.
  text-rendering: optimizeLegibility;
  font-family: $secondary;
}

p + p,
p + img,
img + p {
  margin-top: 1.45em;
}

article {
  ul,
  ol {
    margin-left: 1em;
  }

  li + li {
    margin-top: vr(0.5);
  }

  ul li {
    list-style-type: disc;
  }

  ol li {
    list-style-type: decimal;
  }
}

h1 {
  // @include typi($h1-font-map);
  font-family: $secondary;
  font-size: 1.5625em;
  
  @include max-bp($medium) {
    font-size: 2.6875em;
  }
}

h2 {
  font-family: $secondary;
  line-height: 2.9rem;
}

h3 {
  font-family: $secondary;
  line-height: 2.9rem;
}

h4 {
  font-family: $secondary;
  line-height: 2.9rem;
}

h5 {
 font-family: $secondary;
 line-height: 2.9rem;
}

h6 {
  font-family: $secondary;
  line-height: 2.9rem;
}

a {
  text-decoration: none;
}

button {
  background-color: transparent;
  border: none;
}

button, a {
  cursor: pointer;
}

// THEME RESET
// -----------
// Personal resets in addition to Normalize.scss
// ===========

// Use border-box for everything
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

// Resets margins and paddings for headers, ul, ol and p
h1, h2, h3, h4, h5, h6, ul, li, p {
  margin: 0;
  padding: 0;
}

// Removes all decimals and discs from li
//li {
//  list-style: none;
//}

// Completely resets form items
// ----------------------------
// Super hard reset that removes all borders
// and radiuses of all form items.
// ============================
input,
textarea,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  outline: 0;
  border: 0;
  &:hover,
  &:active,
  &:focus {
    outline: 0;
  }
}

// Set Image and Objects to have a max-width of 100%
embed,
iframe,
img,
object,
video {
  max-width: 100%;
}

// Set Img height and width to auto for IE8
img {
  // IE8
  //height: auto;
  //width: auto;
}

#portal-balloons {
    position: relative;
    z-index: 100;
}